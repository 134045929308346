import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "dynamicField",
    standalone: false
})
export class DynamicFieldPipe implements PipeTransform {
  /**
   *
   */
  constructor() { }
  transform(value: any, ...args: any[]): any {
    if (!value || !args || args.length === 0) {
      return value;
    }

    const column: any = args[0];
    let result = value;
    if (column.field) {
      var fields = column.field.split(".");
      let firstField = fields[0]
      if (fields.length === 1){
        result = result[firstField]
      }
      else{
        if (fields.length > 1 && fields[0]) {
          if (result[firstField] === null || result[firstField]===undefined) {
            return '';
          }
          else{
            fields.forEach((f: string) => (result = result[f]));
          }
        }
      }

    }

    //column.field.split(".").forEach((f: string) => (result = result[f]));
    return result;
  }
}
